html:not([dir='rtl']) .form-check-input {
    margin-left: 1rem;
    position: static;
}

.table .form-check-input {
    margin-right: -10px;
}

.table thead th {
    border: none !important;
    border-top: 0.1px solid #edf2f7 !important;
    text-transform: capitalize;
    color: #4b5563;
    font-size: 1.4rem;
}

tr {
    border-bottom: 1px solid #edf2f7;
}

.table-responsive {
    padding-right: 1.5rem;
    border-top: 1px solid #e5e7eb;
}

.table-striped tbody tr:nth-of-type(even) {
    background-color: #eef2ff;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fff;
}

th {
    overflow: scroll;
}

th > svg {
    display: none;
}

.table th {
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 16px;
}

.table td {
    font-size: 14px;
    font-weight: 400;
    color: #6b7280;
    border: none !important;
    min-height: 59px;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 16px;
}

ul.pagination {
    margin-top: 1.5rem;
}

.justify-content-start {
    justify-content: flex-end !important;
}

.page-item.active .page-link {
    background-color: #eb4898;
    border-color: #eb4898;
}

.pagination li:not(:last-of-type) {
    margin-right: 6px;
}

.page-link {
    border-radius: 4px;
    box-shadow: none;
    color: #3f434a;
    font-size: 1.4rem;
    width: 32px;
    text-align: center;
}

.page-item:nth-child(n + 3):nth-last-child(n + 3) .page-link {
    border: none;
}

.page-item:first-of-type .page-link {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}

.page-item:last-child .page-link {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

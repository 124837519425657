@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

html {
  font-size: 62.5%;
}

@media (min-width: 700px) {
  html {
    /* font-size: 16px; */
  }
}

body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  box-sizing: border-box;
  background-color: #F3F4F6;
  overflow: hidden;
  height: 100vh;
}

a {
    text-decoration: none !important;
}

p{
    margin-bottom: 0;
}

::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.8rem;
}
::-webkit-scrollbar-track {
    background: #e5e7eb;
}
::-webkit-scrollbar-thumb {
    background: #9ca3af;
    border-radius: 100px;
}
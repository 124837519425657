.date-picker-wrapper {
    @apply border-[1px] border-[#E5E7EB] pl-4 rounded outline-none;
}
.react-datepicker {
    @apply border-0 rounded-lg px-10 pb-10 shadow-lg bg-white pt-4 text-lg;
}

.react-datepicker__current-month,.react-datepicker__year-text {
    @apply text-lg;
}

.react-datepicker__navigation{
    @apply focus:outline-none
}

.date-select-field {
    @apply py-2.5 text-left transition-all focus:outline-none duration-100 ease-linear w-full;
}

.date__wrapper {
    @apply absolute z-10 mt-0;
}

.date__wrapper .react-datepicker__day-names {
    @apply pt-1 capitalize text-lg px-6 font-medium;
}

.date__wrapper .react-datepicker__header {
    @apply bg-white border-0 text-lg;
}

.date__wrapper .react-datepicker__day-name,
.react-datepicker__day,
.date__wrapper .react-datepicker__time-name {
    @apply bg-white text-lg pl-5 pr-7 py-2 text-center;
}

.date__wrapper .react-datepicker__day--in-range,
.date__wrapper
    .react-datepicker__day--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ) {
    @apply bg-blue-500 text-black;
}
.date__wrapper .react-datepicker__day--keyboard-selected,
.date__wrapper .react-datepicker__month-text--keyboard-selected,
.date__wrapper .react-datepicker__quarter-text--keyboard-selected,
.date__wrapper .react-datepicker__year-text--keyboard-selected,
.date__wrapper .react-datepicker__day--selected,
.date__wrapper .react-datepicker__day--range-end,
.date__wrapper .react-datepicker__day--keyboard-selected:hover {
    @apply bg-blue-500 text-white rounded-[99px];
}

.react-datepicker__navigation {
    @apply mt-3 mx-24;
}
/* .react-datepicker__navigation--previous */

.react-datepicker__day--outside-month {
    @apply text-[#BFBFBF];
}

/* .react-datepicker__navigation--next {
    background: url(../../assets/icons/arrow-right.svg) no-repeat;
    width: 15px;
    height: 15px;
    border: none;
} */

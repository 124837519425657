.nav-tabs{
    border: none;
}

.nav-tabs .nav-item{
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #6B7280;
    border: none;
}

.nav-tabs a{
    color: #6B7280;

}

.nav-tabs .nav-link {
    border: none;
    border-bottom: 3px solid #E5E7EB;
    /* border-bottom: 1px solid red; */
    margin-right: 1.5rem;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    border-color: #4F46E5;
    color: #1F2937;

}